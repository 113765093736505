import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { environment } from "@env/environment";
import { Subscription } from "rxjs";
import { AppService } from "./core/services/app.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  loading: boolean = true;
  subscriptions: Subscription[] = [];

  constructor(
    private titleService: Title,
    private appService: AppService,
    private router: Router
  ) {
    this.titleService.setTitle(environment.title);
  }

  ngOnInit() {
    this.appService.start().subscribe(
      (_) => {
        this.loading = false;
      },
      (error) => {
        if (!["/account/login", "/account/signup"].includes(this.router.url)) {
          this.router.navigate(["/account/login"]);
        }
        this.loading = false;
      }
    );
  }
}
