import { StateInterface } from '../interfaces';

export class StateModel implements StateInterface {
  starting: boolean = true; // cargando por primera vez => oculta todo
  loading: boolean = false; // recargando algo => ejemplo paginado
  message: string= "";
  error: boolean= false;

  constructor() {}

  public setStarting(message?: string) {
    this.starting = true;
    this.loading = false;
    this.message = message || "Espere un momento por favor";
    this.error = false;
  }

  public setLoading(message?: string) {
    this.starting = false;
    this.loading = true;
    this.message = message || "Cargando información";
    this.error = false;
  }

  public setError(message?: string) {
    this.starting = false;
    this.loading = false;
    this.message = message || "Ha habido un error";
    this.error = true;
  }


  public setReady() {
    this.starting = false;
    this.loading = false;
    this.message = "";
    this.error = false;
  }
}
