<action-site-create #siteCreateComponent></action-site-create>
<action-rule-test #ruleTestComponent></action-rule-test>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Under Construction</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="">
      This feature is under construction. If you have any doubts, contact the
      system administrator. <i class="ti-heart"></i>
    </p>
  </div>
  <div class="modal-footer p-3">
    <button
      type="button"
      class="btn btn-outline-primary"
      (click)="modal.close()"
      ngbAutofocus
    >
      I get it
    </button>
  </div>
</ng-template>
