import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActionsEnum } from "@app/core/enums";
import { ActionInterface } from "@app/core/interfaces";
import { environment } from "@env/environment";
import { Observable } from "rxjs";
import { AppService } from "../app.service";
import { BaseService } from "./base.service";

@Injectable()
export class RulesService extends BaseService {
  constructor(protected http: HttpClient, protected appService:AppService) {
    super(http, appService);
    this.entityUrl = "rules";
    this.updateUser = true;
  }

  public getActions(item: any): ActionInterface[] {
    let _actions: ActionInterface[] = [];

    _actions.push({
      title: "Run Rule",
      action: ActionsEnum.RULE_RUN,
      icon: "bx bx-comment-check",
      data: { id: item._id },
    });

    _actions.push({
      title: "Detail",
      action: ActionsEnum.RULE_DETAIL,
      icon: "bx bx-plus",
      data: { id: item._id },
    });

    return _actions;
  }

  public test(id: number): Observable<any> {
    return this.http.get(`${environment.api}${this.entityUrl}/${id}/test`);
  }
}
