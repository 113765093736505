<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a href="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/logo.svg" alt="" height="22" />
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-dark.png" alt="" height="17" />
          </span>
        </a>

        <a href="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/icono-light.svg" alt="" height="22" />
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-light.svg" alt="" height="19" />
          </span>
        </a>
      </div>

      <button
        type="button"
        class="btn btn-sm px-3 font-size-16 header-item"
        id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)"
      >
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <!-- Account -->
      <div class="dropdown ml-1" ngbDropdown>
        <button
          type="button"
          class="btn header-item d-none d-lg-inline-block"
          ngbDropdownToggle
        >
          <ng-container *ngIf="!currentBusiness; else accountSelector">
            Select a business
          </ng-container>
          <ng-template #accountSelector>
            Business
            <span class="font-weight-bold ml-2">{{
              currentBusiness?.name
            }}</span>
          </ng-template>
          <i class="mdi mdi-menu-down-outline"></i>
        </button>
        <button class="btn header-item noti-icon d-lg-none" ngbDropdownToggle>
          <i class="mdi mdi-briefcase-account"></i>
        </button>
        <div
          class="dropdown-menu dropdown-menu-lg dropdown-menu-left"
          ngbDropdownMenu
        >
          <div class="px-lg-2">
            <ng-container *ngFor="let account of user?.accounts">
              <ng-container *ngFor="let business of account.businesses">
                <a
                  (click)="setBusiness(business)"
                  class="dropdown-icon-item"
                  [class.selected]="
                    currentBusiness && currentBusiness._id === business._id
                  "
                  href="javascript: void(0);"
                >
                  <span>{{ business.name }}</span>
                </a>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex">
      <div class="dropdown d-none d-lg-inline-block ml-1">
        <button
          type="button"
          class="btn header-item noti-icon"
          data-toggle="reload"
          (click)="reload()"
        >
          <i class="mdi mdi-autorenew"></i>
        </button>
      </div>

      <div class="dropdown d-none d-lg-inline-block ml-1">
        <button
          type="button"
          class="btn header-item noti-icon"
          data-toggle="fullscreen"
          (click)="fullscreen()"
        >
          <i class="bx bx-fullscreen"></i>
        </button>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button
          type="button"
          class="btn header-item"
          ngbDropdownToggle
          id="page-header-user-dropdown"
        >
          <img
            class="rounded-circle header-profile-user"
            src="assets/images/users/avatar-1.jpg"
            alt="Header Avatar"
          />
          <span class="d-none d-xl-inline-block ml-1"
            >{{ user?.name }} {{ user?.lastName }}</span
          >
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
          <!-- item-->
          <a
            routerLink="/settings"
            class="dropdown-item d-block"
            href="javascript: void(0);"
            ><i class="bx bx-wrench font-size-16 align-middle mr-1"></i>
            Settings
          </a>
          <div class="dropdown-divider"></div>
          <a
            class="dropdown-item text-danger"
            href="javascript: void(0);"
            (click)="logout()"
            ><i
              class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"
            ></i
            >Logout</a
          >
        </div>
      </div>
    </div>
  </div>
</header>
