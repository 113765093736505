<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Test Rule</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="!state.starting && !state.error && !state.loading">
      <ng-container *ngTemplateOutlet="modalContent"> </ng-container>
    </div>
    <state [state]="state" (recargar)="reload()"></state>
  </div>
  <div class="modal-footer p-3">
    <button
      type="button"
      class="btn btn-light"
      (click)="modal.close()"
      ngbAutofocus
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary"
      [disabled]="state.loading || state.error || state.starting"
      (click)="send()"
    >
      Test
    </button>
  </div>
</ng-template>

<ng-template #modalContent>
  Test Rule

  <div *ngIf="event">
    <app-rule-event-preview [event]="event"></app-rule-event-preview>
  </div>
</ng-template>
