import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { isEmpty } from "@app/shared/utils/is-empty";
import { AppService } from "../services/app.service";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private appService: AppService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.appService.currentUser$.value;
    if (currentUser) {
      if (!isEmpty(route.data) && ![].includes(route.data.feature)) {
        this.router.navigate(["/upgrade"], {
          queryParams: { returnUrl: state.url },
        });
        return true;
      } else {
        return true;
      }
    }
    this.appService.logout();
    this.router.navigate(["/account/login"], {
      queryParams: { returnUrl: state.url },
    });
    return false;
  }
}
