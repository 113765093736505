import { Component, Input, EventEmitter, OnInit, Output } from "@angular/core";
import { StateModel } from 'src/app/core/models';

@Component({
  selector: "state",
  templateUrl: "./state.component.html",
  styleUrls: ["./state.component.scss"]
})
export class StateComponent implements OnInit {
  @Input() state: StateModel;

  @Output() recargar = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onRecargar() {
    this.recargar.emit("");
  }
}
