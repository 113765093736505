import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActionInterface } from "@app/core/interfaces";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AppService } from "../app.service";

@Injectable()
export class BaseService {
  protected entityUrl: string;
  protected updateUser: boolean = false;
  protected addBusiness: boolean = true;

  constructor(protected http: HttpClient, protected appService: AppService) {}

  public get(filtros: any = {}): Observable<any> {
    if (this.addBusiness)
      filtros.business = this.appService.currentBusiness$.value._id;
    const query: string = Object.getOwnPropertyNames(filtros)
      .reduce((res: string[], key: string) => {
        res.push(`${key}=${filtros[key]}`);
        return res;
      }, [])
      .join("&");
    return this.http.get(`${environment.api}${this.entityUrl}?${query}`).pipe(
      map((res: any) => {
        res.list = res.list.map((item) => {
          item._actions = this.getActions(item);
          return item;
        });
        return res;
      })
    );
  }

  public search(filtros: any = {}): Observable<any> {
    const query: string = Object.getOwnPropertyNames(filtros)
      .reduce((res: string[], key: string) => {
        res.push(`${key}=${filtros[key]}`);
        return res;
      }, [])
      .join("&");
    return this.http.get(`${environment.api}${this.entityUrl}/search?${query}`);
  }

  public getById(id: number): Observable<any> {
    return this.http.get(`${environment.api}${this.entityUrl}/${id}`);
  }

  public create(datos: any): Observable<any> {
    return this.http.post(`${environment.api}${this.entityUrl}`, datos).pipe(
      map((res) => {
        if (this.updateUser) this.appService.reloadUser$.next();
        else this.appService.reload$.next();
        return res;
      })
    );
  }

  public update(id: number, datos: any, mensaje?: string): Observable<any> {
    return this.http
      .put(`${environment.api}${this.entityUrl}/${id}`, datos)
      .pipe(
        map((res) => {
          //TODO: update
          return res;
        })
      );
  }

  public delete(id: number): Observable<any> {
    return this.http.delete(`${environment.api}${this.entityUrl}/${id}`);
  }

  public getActions(item: any): ActionInterface[] {
    return [];
  }
}
