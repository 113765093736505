import { MenuItem } from "./menu.model";

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: "Dashboard",
    icon: "mdi mdi-monitor-dashboard",
    link: "/dashboard",
  },
  {
    id: 2,
    label: "Tools",
    isTitle: true,
  },
  {
    id: 3,
    label: "Rules",
    icon: "mdi mdi-format-list-checks",
    link: "/rules",
  },
  {
    id: 4,
    label: "Alerts",
    icon: "mdi mdi-format-list-checks",
    link: "/alerts",
  },
  {
    id: 5,
    label: "Settings",
    isTitle: true,
  },
  {
    id: 6,
    label: "Accounts",
    icon: "bx bx-briefcase-alt",
    link: "/accounts",
  },
];
