import { Component } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { SitesService } from "@app/core/services/abms/sites.service";
import { AppService } from "@app/core/services/app.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IOption } from "ng-select";
import { ActionBaseCreateComponent } from "../base-create.component";

@Component({
  selector: "action-site-create",
  templateUrl: "./site-create.component.html",
})
export class ActionSiteCreateComponent extends ActionBaseCreateComponent {
  public businessOptions: Array<IOption>;

  constructor(
    public formBuilder: FormBuilder,
    public modalService: NgbModal,
    public appServices: AppService,
    private sitesService: SitesService
  ) {
    super(formBuilder, modalService);
  }

  ngOnInit() {}

  init(data?: any) {
    this.state.setReady();
    this.form = this.formBuilder.group({
      name: ["", Validators.required],
      business: ["", Validators.required],
    });
    this.modalService.open(this.content);

    try {
      this.businessOptions = this.appServices.currentBusiness$.value.businesses.map(
        (b) => {
          return {
            value: b._id,
            label: b.name,
          };
        }
      );
    } catch (_) {}
  }

  send() {
    if (!this.form.valid) {
      this.isSubmit = true;
      return;
    }

    this.state.setLoading();
    this.sitesService
      .create({
        name: this.form.controls.name.value,
        businessId: this.form.controls.business.value,
      })
      .subscribe(
        (_) => {
          this.state.setReady();
          this.modalService.dismissAll();
        },
        (error) => {
          this.state.setReady();
          this.modalService.dismissAll();
        }
      );
  }

  reload() {
    this.state.setLoading();
  }
}
