import { Component, Input, OnInit } from "@angular/core";
import { LoaderService } from "../../../core/services/loader.service";

@Component({
  selector: "app-rule-event-preview",
  templateUrl: "./rule-event-preview.component.html",
  styleUrls: ["./rule-event-preview.component.scss"],
})
export class RuleEventPreviewComponent implements OnInit {
  @Input()
  event: any;

  ngOnInit(): void {}
}
