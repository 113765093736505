import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { StateModel } from "@app/core/models";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({ template: "" })
export class ActionBaseComponent implements OnInit {
  @ViewChild("content", { static: true })
  public content: any;

  public data : any;

  public state: StateModel = new StateModel();

  constructor(public modalService: NgbModal) {}

  ngOnInit() {}

  init(data?: any) {
    this.data = data;
    this.state.setReady();
    this.modalService.open(this.content);
  }

  send() {
    return;
  }

  reload() {
    return;
  }
}
