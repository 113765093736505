<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add Site</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="!state.starting && !state.error && !state.loading">
      <ng-container *ngTemplateOutlet="formContent"></ng-container>
    </div>
    <state [state]="state" (recargar)="reload()"></state>
  </div>
  <div class="modal-footer p-3">
    <button
      type="button"
      class="btn btn-light"
      (click)="modal.close()"
      ngbAutofocus
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary"
      [disabled]="
        state.loading || state.error || state.starting || form.invalid
      "
      (click)="send()"
    >
      Send
    </button>
  </div>
</ng-template>

<ng-template #formContent>
  <form
    [formGroup]="form"
    class="editForm"
    novalidate
    autocomplete="doNotAutoComplete"
    readonly
  >
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="form-control-label" for="name">Name</label>
          <input
            class="form-control input-md"
            id="name"
            name="name"
            type="text"
            placeholder="Ingrese un Nombre"
            [ngClass]="{
              'is-invalid':
                !form.controls.name.valid &&
                (form.controls.name.dirty ||
                  form.controls.name.touched ||
                  isSubmit)
            }"
            formControlName="name"
            autocomplete="off"
          />
          <label
            *ngIf="
              !form.controls.name.valid &&
              (form.controls.name.dirty ||
                form.controls.name.touched ||
                isSubmit)
            "
            id="validation-name-error"
            class="error jquery-validation-error small form-text invalid-feedback"
            >Invalid.</label
          >
        </div>
      </div>
      <div class="col-sm-12">
        <label class="form-control-label" for="password">Business</label>
        <ng-select
          [ngClass]="'ng-select'"
          [options]="businessOptions"
          autocomplete="doNotAutoComplete"
          formControlName="business"
          [ngClass]="{
            'is-invalid':
              !form.controls.business.valid &&
              (form.controls.business.dirty ||
                form.controls.business.touched ||
                isSubmit)
          }"
        ></ng-select>
        <label
          *ngIf="
            !form.controls.business.valid &&
            (form.controls.business.dirty ||
              form.controls.business.touched ||
              isSubmit)
          "
          id="validation-business-error"
          class="error jquery-validation-error small form-text invalid-feedback"
          >Invalid</label
        >
      </div>
    </div>
  </form>
</ng-template>
