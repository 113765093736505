import { Component } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { RulesService } from "@app/core/services/abms/rules.service";
import { SitesService } from "@app/core/services/abms/sites.service";
import { AppService } from "@app/core/services/app.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ActionBaseComponent } from "../base.component";

@Component({
  selector: "action-rule-test",
  templateUrl: "./rule-test.component.html",
})
export class ActionRuleTestComponent extends ActionBaseComponent {
  event;
  constructor(
    public modalService: NgbModal,
    public appServices: AppService,
    private rulesService: RulesService
  ) {
    super(modalService);
  }

  ngOnInit() {}

  send() {
    this.state.setLoading();
    this.rulesService.test(this.data.id).subscribe(
      (event) => {
        this.event = event;
        this.state.setReady();
      },
      (error) => {
        this.state.setReady();
      }
    );
  }

  reload() {
    this.state.setLoading();
  }
}
