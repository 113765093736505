import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RuleEventPreviewComponent } from './rule-event-preview/rule-event-preview.component';

@NgModule({
  declarations: [RuleEventPreviewComponent],
  imports: [
    CommonModule,
  ],
  exports: [RuleEventPreviewComponent]
})
export class ComponentsModule { }
