import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { ActionInterface } from "../interfaces";

@Injectable()
export class ActionsService {
  public action: Subject<ActionInterface>;

  constructor() {
    this.action = new Subject();
  }

  init(action: ActionInterface) {
    this.action.next(action);
  }
}
