import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder, FormGroup
} from "@angular/forms";
import { StateModel } from '@app/core/models';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({ template: "" })
export class ActionBaseCreateComponent implements OnInit {
  @ViewChild("content", { static: true })
  public content: any;

  public form: FormGroup;
  public isSubmit: boolean;
  public state: StateModel = new StateModel();

  constructor(
    public formBuilder: FormBuilder,
    public modalService: NgbModal
  ) {}

  ngOnInit() {}

  init(data?:any) {
    this.form = this.formBuilder.group({});
    this.modalService.open(this.content);
  }

  send() {
    if (!this.form.valid) {
      this.isSubmit = true;
      return;
    }
  }

  reload() {
    return;
  }
}
