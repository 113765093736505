import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ActionInterface } from "@core/interfaces";
import { ActionsEnum } from "@core/enums";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ActionsService } from "@app/core/services/actions.service";
import { ActionSiteCreateComponent } from "./actions/site-create/site-create.component";
import { ActionRuleTestComponent } from "./actions/rule-test/rule-test.component";

@Component({
  selector: "actions-component",
  templateUrl: "./actions.component.html",
})
export class ActionsComponent implements OnInit {
  @ViewChild("content", { static: true })
  public content: any;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    public actionsService: ActionsService,
  ) {}

  @ViewChild("siteCreateComponent", { static: true })
  public siteCreateComponent: ActionSiteCreateComponent;

  @ViewChild("ruleTestComponent", { static: true })
  public ruleTestComponent: ActionRuleTestComponent;

  ngOnInit() {
    this.actionsService.action.subscribe((action) => {
      this.init(action);
    });
  }

  public init(action: ActionInterface) {
    switch (action.action) {
      case ActionsEnum.SITE_CREATE:
        this.siteCreateComponent.init(action.data);
        break;
      case ActionsEnum.RULE_DETAIL:
        this.router.navigate([`/rules/${action.data.id}`]);
        break;
      case ActionsEnum.RULE_RUN:
        this.ruleTestComponent.init(action.data);
        break;
      default:
        this.showComingSoonDialog("Próximamente", "info");
        break;
    }
  }

  public showComingSoonDialog(title: string, icon?: string) {
    this.modalService.open(this.content);
  }
}
