<div *ngIf="state.starting">
  <div class="lds-ring lds-ring-primary m-auto"><div></div><div></div><div></div><div></div></div>
  <p class="text-muted mb-4 mt-5 font-16">{{ state.message || "Espere un momento por favor..." }}</p>
</div>
<div *ngIf="state.error">
  <div class="box-wrapper p-4">
    <div class="error-content py-3">
      <h1 class="error-code text-muted font-weight-normal mb-4"><i style="font-size: 40px" class="fas fa-exclamation-triangle"></i></h1>
      <h3 class="text-muted mb-4">¡Algo ha salido mal!</h3>
      <p class="text-muted mb-4 font-16">{{ state.message }}</p>
      <div>
        <button class="btn btn-outline-primary" (click)="onRecargar()">
          <span class="btn-icon">Volver a Intentar</span>
        </button>
      </div>
    </div>
  </div>
</div>
