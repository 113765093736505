import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { isEmpty } from "@app/shared/utils/is-empty";
import { environment } from "@env/environment";
import { BehaviorSubject, Observable, ReplaySubject } from "rxjs";
import { map } from "rxjs/operators";
import {
  AuthenticatedUserInterface,
  ModuleInterface,
  NotificationInterface,
} from "../interfaces";

const serviceDefault: ModuleInterface = {
  notifications: [],
  updatedAt: new Date(),
};

@Injectable({ providedIn: "root" })
export class AppService {
  public notifications$: BehaviorSubject<NotificationInterface[]>;
  public updates = {
    user: new ReplaySubject(1),
    modules: new ReplaySubject(1),
  };

  public currentSite$: BehaviorSubject<any>;
  public currentUser$: BehaviorSubject<AuthenticatedUserInterface>;
  public currentBusiness$: BehaviorSubject<any>;
  public reload$ = new ReplaySubject(1);
  public reloadUser$ = new ReplaySubject(1);

  constructor(private http: HttpClient) {
    this.currentSite$ = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem("currentSite")) || null
    );
    this.currentBusiness$ = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem("currentBusiness")) || null
    );
    this.currentUser$ = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem("currentUser")) || null
    );

    this.currentUser$.subscribe((user) => {
      if (!isEmpty(user)) {
        let currentBusiness =
          JSON.parse(localStorage.getItem("currentBusiness") || "{}") || null;

        let businesses = (currentBusiness = user.accounts.reduce((res, a) => {
          res = res.concat(a.businesses);
          return res;
        }, []));

        if (!isEmpty(currentBusiness)) {
          currentBusiness = businesses.filter(
            (a) => a._id == currentBusiness._id
          )[0];
        }
        if (isEmpty(currentBusiness)) {
          currentBusiness = businesses[0];
        }
        this.setCurrentBusiness(currentBusiness);
      } else {
        this.currentBusiness$.next(null);
      }
    });

    this.reloadUser$.subscribe(() => {
      this.loadUser().subscribe((_) => {});
    });

    this.currentBusiness$.subscribe((account) => {
      //TO DO : Get last used or first site.
      this.currentSite$.next(null);
    });
  }

  public start(): Observable<any> {
    return this.loadUser();
  }

  public setCurrentSite(site) {
    localStorage.setItem("currentSite", JSON.stringify(site));
    this.currentSite$.next(site);
  }

  public setCurrentUser(user) {
    localStorage.setItem("currentUser", JSON.stringify(user));
    this.currentUser$.next(user);
  }

  public setCurrentBusiness(b) {
    localStorage.setItem("currentBusiness", JSON.stringify(b));
    this.currentBusiness$.next(b);
  }

  public loadUser(): Observable<AuthenticatedUserInterface> {
    return this.http
      .get<AuthenticatedUserInterface>(`${environment.api}user`)
      .pipe(
        map((user: AuthenticatedUserInterface) => {
          this.setCurrentUser(user);
          return user;
        })
      );
  }

  public login(
    email: string,
    password: string
  ): Observable<AuthenticatedUserInterface> {
    return this.http
      .post(environment.api + "login", {
        email,
        password,
      })
      .pipe(
        map((res: any) => {
          if (res.user && res.token) {
            localStorage.setItem("currentUser", JSON.stringify(res.user));
            localStorage.setItem("token", res.token);
            this.currentUser$.next(res.user);
          }
          return res.user;
        })
      );
  }

  public register(user: any): Observable<AuthenticatedUserInterface> {
    return this.http.post(`${environment.api}registration`, user).pipe(
      map((res: any) => {
        if (res.user && res.token) {
          localStorage.setItem("currentUser", JSON.stringify(res.user));
          localStorage.setItem("token", res.token);
          this.currentUser$.next(res.user);
        }
        return res.user;
      })
    );
  }

  public logout() {
    localStorage.clear();
    this.currentUser$.next(null);
  }
}
