import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { ActionsComponent } from "./actions.component";
import { ActionsActionComponent } from "./components/action/action.component";
import { ActionSiteCreateComponent } from "./actions/site-create/site-create.component";
import { SelectModule } from "ng-select";
import { StateComponent } from "../ui/state/state.component";
import { ActionsActionsComponent } from "./components/actions/actions.component";
import { ActionRuleTestComponent } from "./actions/rule-test/rule-test.component";
import { ComponentsModule } from "../components/components.module";

@NgModule({
  imports: [CommonModule, NgbTooltipModule, ReactiveFormsModule, SelectModule, ComponentsModule],
  declarations: [ActionsComponent, ActionsActionComponent, ActionsActionsComponent, ActionSiteCreateComponent, StateComponent, ActionRuleTestComponent],
  exports: [CommonModule, ActionsComponent, StateComponent, ActionsActionComponent, ActionsActionsComponent, ActionRuleTestComponent],
  entryComponents: [ActionsActionComponent, ActionsActionsComponent],
})
export class ActionsModule {}
