import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppService } from "../app.service";
import { BaseService } from "./base.service";

@Injectable()
export class SitesService extends BaseService {
  constructor(protected http: HttpClient, protected appService:AppService) {
    super(http, appService);
    this.entityUrl = "sites";
    this.updateUser = true;
  }
}
